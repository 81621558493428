import { TitanEventSource, TitanEventSourceAttribute, TitanVirtualEventSource } from '@cognitiv/galaxy-api';
import { VALIDATE_TITAN_CREATIVE_VIEW } from 'products/titan/modals/bulk-validate-titan-creative/enums';
import { TITAN_CREATIVE_TYPE_ID } from 'products/titan/operators/creative-types-list/enums';
import { TITAN_GENERATE_TYPE_ID } from 'products/titan/pages/creatives/enums';

export enum TITAN_BULK_MANAGE_CREATIVE_VIEW {
  CREATE = 1,
  UPDATE = 2,
}

export enum TITAN_MANAGE_CAMPAIGN_ATTRIBUTION_VIEW {
  MANAGE = 1,
  UPDATE_BACKFILL_DAYS = 2,
  READ_ONLY = 3,
}

export interface TitanEventSourceForm extends TitanEventSource {
  attributes: TitanEventSourceAttribute[];
}

export interface TitanVirtualEventSourceForm extends TitanVirtualEventSource {
  attributes: TitanEventSourceAttribute[];
}

export interface Modal {
  is_open: boolean;
}

export interface TitanCampaignNoteModal extends Modal {
  default_campaign_note_category_id: number | null;
  default_campaign_note_category_name: string;
  campaign_id: number | null;
  campaign_name: string;
}

export interface TitanSalesforceOpportunityModal extends Modal {
  product_type_id: number;
}

export interface ValidateTitanCreativeModal extends Modal {
  modal_view: VALIDATE_TITAN_CREATIVE_VIEW;
}

export interface TitanManageCreativeModal extends Modal {
  creative_type_id: TITAN_CREATIVE_TYPE_ID;
}

export interface TitanBulkManageCreativeModal extends Modal {
  advertiser_id: number | null;
  advertiser_name: string;
  view: TITAN_BULK_MANAGE_CREATIVE_VIEW;
}

export interface TitanCreateCreativeMessageModal extends Modal {
  advertiser_id: number | null;
  advertiser_name: string;
}

export interface TitanGenerateTemplateModal extends Modal {
  advertiser_id: number | null;
  advertiser_name: string;
  titan_generate_type: TITAN_GENERATE_TYPE_ID;
}

export interface TitanCampaignAttributionModal extends Modal {
  view: TITAN_MANAGE_CAMPAIGN_ATTRIBUTION_VIEW;
}

export interface TitanModals {
  manage_titan_advertiser: Modal;
  manage_titan_partner: Modal;
  manage_titan_universal_pixel: Modal;
  manage_titan_individual_pixel: Modal;
  manage_titan_location_event: Modal;
  manage_titan_cpg_event: Modal;
  manage_titan_virtual_event_source: Modal;
  update_titan_event_source_note: Modal;
  manage_titan_virtual_event_source_note: Modal;
  manage_titan_campaign_attribution: TitanCampaignAttributionModal;
  manage_titan_campaign_note: TitanCampaignNoteModal;
  manage_titan_campaign_team: Modal;
  select_titan_salesforce_opportunity: TitanSalesforceOpportunityModal;
  view_s3_objects: Modal;
  manage_titan_notification: Modal;
  bulk_validate_titan_creative: ValidateTitanCreativeModal;
  manage_titan_creative: TitanManageCreativeModal;
  validate_titan_creative: Modal;
  bulk_manage_titan_creatives: TitanBulkManageCreativeModal;
  generate_titan_template: TitanGenerateTemplateModal;
  create_creative_message: TitanCreateCreativeMessageModal;
}
