import virgoCampaignSlice from 'products/virgo/operators/campaign/slices';
import virgoCampaignsSlice from 'products/virgo/operators/campaigns/slices';
import virgoDealSlice from 'products/virgo/operators/deal/slices';
import virgoDealsSlice from 'products/virgo/operators/deals/slices';

export const virgo = {
  virgo_campaign: virgoCampaignSlice,
  virgo_campaigns: virgoCampaignsSlice,
  virgo_deal: virgoDealSlice,
  virgo_deals: virgoDealsSlice,
};
