import { colors } from 'theme/colors';

export const STATE_THEME = {
  // BLUE GREY
  created: colors.blue_grey[5],
  pending: colors.blue_grey[5],
  not_labeling: colors.blue_grey[5],
  not_started: colors.blue_grey[5],
  configuring: colors.blue_grey[5],
  identifying: colors.blue_grey[5],
  skipped: colors.blue_grey[5],
  // BLUE
  queued: colors.blue[5],
  running: colors.blue[5],
  in_progress: colors.blue[5],
  untested: colors.blue[5],
  awaiting_bids: colors.blue[5],
  model_training: colors.blue[5],
  setting_up: colors.blue[5],
  gathering_data: colors.blue[5],
  queued_for_processing: colors.blue[5],
  processing: colors.blue[5],
  // GREEN
  active: colors.green[5],
  ready: colors.green[5],
  succeeded: colors.green[5],
  bids_available: colors.green[5],
  ok: colors.green[5],
  labeling: colors.green[5],
  online: colors.green[5],
  accepted: colors.green[5],
  passed: colors.green[5],
  live: colors.green[5],
  healthy: colors.green[5],
  // ORANGE
  paused: colors.orange[5],
  live_pending: colors.orange[5],
  awaiting_filter: colors.orange[5],
  waiting_for_capacity: colors.orange[5],
  warning: colors.orange[5],
  testing: colors.orange[5],
  stopped: colors.orange[5],
  staged: colors.orange[5],
  // RED
  failed: colors.red[5],
  deal_inactive: colors.red[5],
  timed_out: colors.red[5],
  error: colors.red[5],
  unhealthy: colors.red[5],
  // GREY
  archived: colors.neutral[5],
  inactive: colors.neutral[5],
  offline: colors.neutral[5],
  ignored: colors.neutral[5],
};
