import React from 'react';
import { Icon, Label } from '@cognitiv/cassiopeia-ui';
import { Option, ShowAllOptions, ShowMoreOptions } from 'overlays/mega-menu/components/option/Option';
import { MEGA_MENU_CHUNK_META_DATA, PUBLIC_URL } from 'overlays/mega-menu/constants';
import { MEGA_MENU_VIEW } from 'overlays/mega-menu/enums';
import { useMegaMenuContext } from 'overlays/mega-menu/MegaMenuContext';
import { MegaMenuChunk } from 'overlays/mega-menu/types';
import { colors } from 'theme/colors';

import cn from 'overlays/mega-menu/components/column/Column.module.scss';

export const Column = ({ chunk }: { chunk: MegaMenuChunk }) => {
  const { view, updateView } = useMegaMenuContext();
  const { entity_type, data, more_options, is_first_of_type = true } = chunk;

  const { icon, label, image } = entity_type ? MEGA_MENU_CHUNK_META_DATA[entity_type] : { icon: null, label: null, image: null };

  if (!data.length && is_first_of_type) {
    const message = `No ${label || 'data'} for this advertiser`;
    return (
      <div className={cn.empty}>
        <img src={image || `${PUBLIC_URL}/mega-menu-campaign.png`} />
        {message}
      </div>
    );
  }

  return (
    <div className={cn.column}>
      {view === MEGA_MENU_VIEW.ALL && is_first_of_type && (
        <div className={cn.header}>
          {icon && <Icon icon={icon} theme={{ icon: { font_size: '15px', color: colors.pink[6] } }} />}
          {label && <Label label={label} margin="0px" theme={{ label: { font_weight: 500 }, font: { general: { font_size_normal: '15px' } } }} />}
        </div>
      )}
      <div className={cn.content}>
        {data.map((option) => (
          <Option key={option.uuid} option={option} />
        ))}
        {!!more_options && (view === MEGA_MENU_VIEW.ALL ? <ShowMoreOptions onClick={() => updateView(entity_type)} /> : <ShowAllOptions />)}
      </div>
    </div>
  );
};
