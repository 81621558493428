import { HyperionDspLineItemDealMapping } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/hyperion/operators/slice';

const initialState: HyperionDspLineItemDealMapping[] = [];

export const hyperionDspLineItemDealMappingsSlice = createSlice({
  name: SLICE.HYPERION_DSP_LINE_ITEM_DEAL_MAPPINGS,
  initialState,
  reducers: {
    clearHyperionDspLineItemDealMappings: () => initialState,
    setHyperionDspLineItemDealMappings: (_, action: PayloadAction<HyperionDspLineItemDealMapping[]>) => action.payload,
  },
});

export const { clearHyperionDspLineItemDealMappings, setHyperionDspLineItemDealMappings } = hyperionDspLineItemDealMappingsSlice.actions;

export default hyperionDspLineItemDealMappingsSlice.reducer;
