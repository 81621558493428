import { HyperionCsCampaignPerformanceTracker } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { hyperion_cs_campaign_performance_tracker_default } from 'products/hyperion/operators/cs-campaign-performance/defaults';
import { SLICE } from 'products/hyperion/operators/slice';

const initialState: HyperionCsCampaignPerformanceTracker = { ...hyperion_cs_campaign_performance_tracker_default };

export const hyperionCsCampaignPerformanceTrackerSlice = createSlice({
  name: SLICE.HYPERION_CS_CAMPAIGN_PERFORMANCE_TRACKER,
  initialState,
  reducers: {
    clearHyperionCsCampaignPerformanceTracker: () => initialState,
    setHyperionCsCampaignPerformanceTracker: (_, action: PayloadAction<HyperionCsCampaignPerformanceTracker>) => action.payload,
  },
});

export const { clearHyperionCsCampaignPerformanceTracker, setHyperionCsCampaignPerformanceTracker } =
  hyperionCsCampaignPerformanceTrackerSlice.actions;

export default hyperionCsCampaignPerformanceTrackerSlice.reducer;
