import { TitanEventSource } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/titan/operators/slice';

const initialState: TitanEventSource[] = [];

export const titanCampaignSupplementalEventSourcesSlice = createSlice({
  name: SLICE.TITAN_CAMPAIGN_SUPPLEMENTAL_EVENT_SOURCES,
  initialState,
  reducers: {
    clearTitanCampaignSupplementalEventSources: () => initialState,
    setTitanCampaignSupplementalEventSources: (_, action: PayloadAction<TitanEventSource[]>) => action.payload,
  },
});

export const { clearTitanCampaignSupplementalEventSources, setTitanCampaignSupplementalEventSources } =
  titanCampaignSupplementalEventSourcesSlice.actions;

export default titanCampaignSupplementalEventSourcesSlice.reducer;
