import { TitanFlight } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { titan_flight_default } from 'products/titan/operators/flight/defaults';
import { SLICE } from 'products/titan/operators/slice';

const initialState: TitanFlight = { ...titan_flight_default };

export const titanFlightSlice = createSlice({
  name: SLICE.TITAN_FLIGHT,
  initialState,
  reducers: {
    clearTitanFlight: () => initialState,
    setTitanFlight: (_, action: PayloadAction<TitanFlight>) => action.payload,
  },
});

export const { clearTitanFlight, setTitanFlight } = titanFlightSlice.actions;

export default titanFlightSlice.reducer;
