import { TitanAttributionIdentityType } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/titan/operators/slice';

const initialState: TitanAttributionIdentityType[] = [];

export const titanAttributionIdentityTypesListSlice = createSlice({
  name: SLICE.TITAN_ATTRIBUTION_IDENTITY_TYPES_LIST,
  initialState,
  reducers: {
    clearTitanAttributionIdentityTypesList: () => initialState,
    setTitanAttributionIdentityTypesList: (_, action: PayloadAction<TitanAttributionIdentityType[]>) => action.payload,
  },
});

export const { clearTitanAttributionIdentityTypesList, setTitanAttributionIdentityTypesList } = titanAttributionIdentityTypesListSlice.actions;

export default titanAttributionIdentityTypesListSlice.reducer;
