import React, { useCallback } from 'react';
import { Icon } from '@cognitiv/cassiopeia-ui';
import { faCaretRight, faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { selectOverlay } from 'ducks/overlays/selectors';
import { updateOverlay } from 'ducks/overlays/slices';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import cn from 'components/layout/navigation/Navigation.module.scss';

export const MEGA_MENU_ID = 'MEGA_MENU_ID';

export interface MegaMenuProps {
  label: string;
  page_type_id: number | null;
}

export const MegaMenuBreadcrumb: React.FC<MegaMenuProps> = ({ label, page_type_id }) => {
  const dispatch = useAppDispatch();
  const { is_open } = useAppSelector((state) => selectOverlay(state, 'mega_menu'));

  const onClick = useCallback(() => {
    dispatch(updateOverlay({ mega_menu: { is_open: !is_open, page_type_id: page_type_id } }));
  }, [dispatch, is_open, page_type_id]);

  return (
    <>
      <div className={cn.breadcrumb}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: 'fit-content',
            gap: '16px',
            height: '100%',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={onClick}
          id={MEGA_MENU_ID}
        >
          {label}
          <Icon icon={is_open ? faChevronUp : faChevronDown} />
        </div>
      </div>
      <FontAwesomeIcon icon={faCaretRight} className={cn.icon} />
    </>
  );
};
