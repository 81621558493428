import { GeminiRevenueType } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/gemini/operators/slice';

const initialState: GeminiRevenueType[] = [];

export const geminiRevenueTypesListSlice = createSlice({
  name: SLICE.GEMINI_REVENUE_TYPES_LIST,
  initialState,
  reducers: {
    clearGeminiRevenueTypesList: () => {
      return initialState;
    },
    setGeminiRevenueTypesList: (_, action: PayloadAction<GeminiRevenueType[]>) => action.payload,
  },
});

export const { clearGeminiRevenueTypesList, setGeminiRevenueTypesList } = geminiRevenueTypesListSlice.actions;

export default geminiRevenueTypesListSlice.reducer;
