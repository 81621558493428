import { TitanAttributionModel } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/titan/operators/slice';

const initialState: TitanAttributionModel[] = [];

export const titanAttributionModelsListSlice = createSlice({
  name: SLICE.TITAN_ATTRIBUTION_MODELS_LIST,
  initialState,
  reducers: {
    clearTitanAttributionModelsList: () => initialState,
    setTitanAttributionModelsList: (_, action: PayloadAction<TitanAttributionModel[]>) => action.payload,
  },
});

export const { clearTitanAttributionModelsList, setTitanAttributionModelsList } = titanAttributionModelsListSlice.actions;

export default titanAttributionModelsListSlice.reducer;
