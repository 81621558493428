import React from 'react';
import { Icon, Label } from '@cognitiv/cassiopeia-ui';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import { MEGA_MENU_CHUNK_META_DATA } from 'overlays/mega-menu/constants';
import { MEGA_MENU_ENTITY_TYPE, MEGA_MENU_VIEW } from 'overlays/mega-menu/enums';
import { useMegaMenuContext } from 'overlays/mega-menu/MegaMenuContext';
import { TITAN_PRODUCT_TYPE_ID } from 'products/titan/operators/product-types-list/enums';
import { colors } from 'theme/colors';

import cn from 'overlays/mega-menu/components/side-menu/SideMenu.module.scss';

const theme = { label: { font_weight: 500 }, field: { color: { normal: 'inherit' } }, font: { general: { font_size_normal: '13px' } } };

const getIconTheme = (is_active: boolean) => {
  return { icon: { font_size: '16px', color: is_active ? colors.neutral[1] : colors.pink[6] } };
};

export const SideMenu = () => {
  const { view, updateView, product_type } = useMegaMenuContext();

  const menu_options = [
    {
      type: MEGA_MENU_ENTITY_TYPE.CAMPAIGNS,
      label: 'Campaigns',
      is_visible: true,
      is_active: view === MEGA_MENU_VIEW.CAMPAIGNS,
    },
    {
      type: MEGA_MENU_ENTITY_TYPE.SEGMENTS,
      label: 'Segments',
      is_visible: product_type === TITAN_PRODUCT_TYPE_ID.CONTEXTUAL,
      is_active: view === MEGA_MENU_VIEW.SEGMENTS,
    },
    {
      type: MEGA_MENU_ENTITY_TYPE.DEALS,
      label: 'Deals',
      is_visible: product_type === TITAN_PRODUCT_TYPE_ID.CONTEXTUAL || product_type === TITAN_PRODUCT_TYPE_ID.CURATION,
      is_active: view === MEGA_MENU_VIEW.DEALS,
    },
  ];

  return (
    <div className={cn.wrapper}>
      <div className={cn.back} onClick={() => updateView(null)}>
        <Icon icon={faArrowLeft} />
        <Label label="Back" margin="0px" theme={theme} />
      </div>
      <div className={cn.separator} />
      {menu_options
        .filter((option) => option.is_visible)
        .map((option) => (
          <div key={option.type} className={classNames(cn.option, { [cn.selected]: option.is_active })} onClick={() => updateView(option.type)}>
            <Icon icon={MEGA_MENU_CHUNK_META_DATA[option.type].icon} theme={getIconTheme(option.is_active)} />
            <Label label={option.label} margin="0px" theme={theme} />
          </div>
        ))}
      <div className={cn.grow} />
    </div>
  );
};
