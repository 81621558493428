import { VirgoDeal } from '@cognitiv/galaxy-api';

export const virgo_deal_default: VirgoDeal = {
  deal_id: 0,
  deal_name: '',
  deal_identifier: '',
  client_deal_identifier: '',
  creative_type_id: 0,
  creative_type_name: '',
  inventory_source_id: 0,
  inventory_source_name: '',
  campaign_id: 0,
  campaign_name: '',
  advertiser_id: 0,
  advertiser_name: '',
  advertiser_dsp_seat_id: 0,
  spend_usd: 0,
  spend_usd_formatted: '',
  spend_usd_yesterday: 0,
  spend_usd_yesterday_formatted: '',
  impressions: 0,
  impressions_formatted: '',
  impressions_yesterday: 0,
  impressions_yesterday_formatted: '',
  bid_requests: 0,
  bid_requests_formatted: '',
  bid_requests_yesterday: 0,
  bid_requests_yesterday_formatted: '',
  bid_rate: 0,
  bid_rate_formatted: '',
  bid_rate_yesterday: 0,
  bid_rate_yesterday_formatted: '',
  win_rate: 0,
  win_rate_formatted: '',
  win_rate_yesterday: 0,
  win_rate_yesterday_formatted: '',
  dsp_name: '',
  dsp_id: 0,
  dsp_seat_identifier: '',
  dsp_seat_identifier_formatted: '',
  deal_state_id: 0,
  deal_state_name: '',
  deal_identifier_formatted: '',
  client_deal_identifier_formatted: '',
  real_time_activation_segment_id: 0,
  audience_segment_id: 0,
  contextual_segment_id: 0,
  revision_id: 0,
  deal_margin_percent: 0,
  deal_margin_formatted: '',
  created_at_utc: '',
  created_date_formatted: '',
  note: null,
  zone_id: 0,
  uuid: '',
  data_center_id: 0,
  is_reporting_only: false,
};
