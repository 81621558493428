import { STATE_THEME } from 'theme/theme';

export enum TITAN_GENERATE_TYPE_ID {
  EMPTY_TEMPLATE = 1,
  DCM_UPLOAD = 2,
  ADERIZE_UPLOAD = 3,
  FLASHTALKING_UPLOAD = 4,
}

export enum TITAN_DOWNLOAD_TYPE {
  TTD = 1,
  COGNITIV = 2,
}

export enum TITAN_DOWNLOAD_TYPE_NAME {
  TTD = 'TTD Excel',
  COGNITIV = 'Cognitiv Excel',
}

export enum TITAN_MANAGE_CREATIVES_TYPE {
  CREATE_BANNER = 1,
  CREATE_VIDEO = 2,
  BULK_CREATE = 3,
  BULK_UPDATE = 4,
}

export enum TITAN_MANAGE_CREATIVES_TYPE_NAME {
  CREATE_BANNER = 'Create Banner',
  CREATE_VIDEO = 'Create Video',
  BULK_CREATE = 'Bulk Create',
  BULK_UPDATE = 'Bulk Update',
}

export enum TITAN_CREATIVE_STATE_ID {
  STAGED = 1,
  ACTIVE = 2,
  ARCHIVED = 9,
}

export enum TITAN_CREATIVE_TEST_STATE_ID {
  UNTESTED = 1,
  PASSED = 2,
  FAILED = 3,
  SKIPPED = 4,
  ARCHIVED = 5,
}

export const TITAN_CREATIVE_TEST_STATE_COLORS = {
  [TITAN_CREATIVE_TEST_STATE_ID.UNTESTED]: STATE_THEME.untested,
  [TITAN_CREATIVE_TEST_STATE_ID.PASSED]: STATE_THEME.passed,
  [TITAN_CREATIVE_TEST_STATE_ID.FAILED]: STATE_THEME.failed,
  [TITAN_CREATIVE_TEST_STATE_ID.SKIPPED]: STATE_THEME.skipped,
  [TITAN_CREATIVE_TEST_STATE_ID.ARCHIVED]: STATE_THEME.archived,
};

export enum TITAN_CREATIVES_ACTION {
  EDIT = 1,
  VALIDATE = 2,
  ARCHIVE = 3,
  UNARCHIVE = 4,
}

export enum TITAN_CREATIVES_ACTION_NAME {
  EDIT = 'Edit',
  VALIDATE = 'Validate',
  ARCHIVE = 'Archive',
  UNARCHIVE = 'Unarchive',
}

export enum TITAN_CREATIVE_ADDON {
  CREATIVE_WRAPPER_CORE = 1,
  CREATIVE_WRAPPER_CURATION = 2,
  DAA_TOP_LEFT = 3,
  DAA_TOP_RIGHT = 4,
  IAS_DISPLAY = 5,
  ADCHOICES_TOP_LEFT = 6,
  ADCHOICES_TOP_RIGHT = 7,
  ADCHOICES_BOTTOM_RIGHT = 8,
  ADCHOICES_BOTTOM_LEFT = 9,
}
