import { TitanCampaignAttribution } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { titan_campaign_attribution_default } from 'products/titan/operators/campaign-attribution/defaults';
import { SLICE } from 'products/titan/operators/slice';

const initialState: TitanCampaignAttribution = { ...titan_campaign_attribution_default };

export const titanCampaignAttributionSlice = createSlice({
  name: SLICE.TITAN_CAMPAIGN_ATTRIBUTION,
  initialState,
  reducers: {
    clearTitanCampaignAttribution: () => initialState,
    setTitanCampaignAttribution: (_, action: PayloadAction<TitanCampaignAttribution>) => action.payload,
  },
});

export const { clearTitanCampaignAttribution, setTitanCampaignAttribution } = titanCampaignAttributionSlice.actions;

export default titanCampaignAttributionSlice.reducer;
