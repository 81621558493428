export enum TITAN_PAGE_CATEGORY {
  NAVIGATION = 1,
  RECENTLY = 2,
  SEARCH = 3,
}

export enum KPI_UNIT_TYPE {
  NUMERIC = 1,
  PERCENT = 2,
  CURRENCY = 3,
  SCALAR = 4,
  OTHER = 5,
  DEFAULT = 0,
}

export enum TITAN_CAMPAIGN_STATE_ID {
  CREATED = 1,
  GATHERING_DATA = 2,
  MODEL_TRAINING = 3,
  SETTING_UP = 4,
  LIVE_PENDING = 5,
  LIVE = 6,
  UNHEALTHY = 7,
  OFFLINE = 8,
  ARCHIVED = 99,
}

export const enum TITAN_EVENT_SOURCE_STATE_ID {
  CONFIGURING = 1,
  TESTING = 2,
  ONLINE = 3,
  UNHEALTHY = 4,
  OFFLINE = 5,
  ARCHIVED = 9,
}

export enum TITAN_CAMPAIGN_ACTION {
  EDIT = 1,
  EDIT_TEAM = 2,
  TURN_ACTIVE = 3,
  TURN_OFFLINE = 4,
  ARCHIVE = 5,
  UNARCHIVE = 6,
}

export enum TITAN_EVENT_SOURCE_OPTION_ID {
  EDIT = 1,
  CHANGE_STATE = 2,
}

export enum TITAN_DELIVERY_SOURCE_ID {
  DSP = 1,
  SSP = 2,
  ADSERVER = 3,
  INTERNAL = 4,
  EXTERNAL = 5,
}

export enum TITAN_CAMPAIGN_NOTE_CATEGORY_ID {
  GENERAL = 1,
  OTHER = 2,
  PERFORMANCE = 3,
  PACING = 4,
  SYSTEM = 6,
}

export enum TITAN_DELIVERY_TYPE_ID {
  SLACK = 1,
  EMAIL = 2,
}

export enum TITAN_ATTRIBUTION_IDENTITY_TYPE_ID {
  IID = 1,
  LIVERAMP_IID = 2,
  CUID = 3,
  BROWSER_ID = 4,
  DEVICE_ID = 5,
  PERSON_ID = 6,
  HOUSEHOLD_ID = 7,
  COGNITIV_IID = 8,
}
