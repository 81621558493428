export enum SLICE {
  TITAN_ADVERTISER = 'titan-advertiser',
  TITAN_ADVERTISERS = 'titan-advertisers',
  TITAN_CAMPAIGN_ATTRIBUTION = 'titan-campaign-attribution',
  TITAN_CAMPAIGN_ATTRIBUTIONS = 'titan-campaign-attributions',
  TITAN_ATTRIBUTION_IDENTITY_TYPES_LIST = 'titan-attribution-identity-types-list',
  TITAN_ATTRIBUTION_MODELS_LIST = 'titan-attribution-models-list',
  TITAN_CAMPAIGN = 'titan-campaign',
  TITAN_CAMPAIGN_NOTE = 'titan-campaign-note',
  TITAN_CAMPAIGN_NOTE_CATEGORIES_LIST = 'titan-campaign-note-categories-list',
  TITAN_CAMPAIGN_NOTEs = 'titan-campaign-notes',
  TITAN_CAMPAIGN_ROLE_PERSONS = 'titan-campaign-role-persons',
  TITAN_CAMPAIGN_SUPPLEMENTAL_EVENT_SOURCES = 'titan-campaign-supplemental-event-sources',
  TITAN_CAMPAIGN_TEAM = 'titan-campaign-team',
  TITAN_CAMPAIGNS = 'titan-campaigns',
  TITAN_CREATIVE = 'titan-creative',
  TITAN_CREATIVE_AD_SERVERS_LIST = 'titan-creative-ad-servers-list',
  TITAN_CREATIVE_ADDONS_LIST = 'titan-creative-addons-list',
  TITAN_CREATIVE_BANNER_SIZES_LIST = 'titan-creative-banner-sizes-list',
  TITAN_CREATIVE_MESSAGES = 'titan-creative-messages',
  TITAN_CREATIVE_TYPES_LIST = 'titan-creative-types-list',
  TITAN_CREATIVES = 'titan-creatives',
  TITAN_DELIVERY_SOURCES_LIST = 'titan-delivery-sources-list',
  TITAN_DELIVERY_TYPES_LIST = 'titan-delivery-types-list',
  TITAN_DELIVERY_VALUES = 'titan-delivery-values',
  TITAN_DSPS_LIST = 'titan-dsps-list',
  TITAN_EVENT_SOURCE = 'event-source',
  TITAN_EVENT_SOURCE_CATEGORIES_LIST = 'titan-event-source-categories-list',
  TITAN_EVENT_SOURCE_DATA_PROVIDER_S3_OBJECTS_LIST = 'titan-event-source-data-provider-s3-objects-list',
  TITAN_EVENT_SOURCE_OPERATOR_TYPES_LIST = 'titan-event-source-operator-types-list',
  TITAN_EVENT_SOURCE_PROPERTY_TYPES_LIST = 'titan-event-source-property-types-list',
  TITAN_EVENT_SOURCE_TYPES_LIST = 'titan-event-source-types-list',
  TITAN_EVENT_SOURCES = 'titan-event-sources',
  TITAN_EVENT_SOURCES_ATTRIBUTES = 'titan-event-source-attributes',
  TITAN_EVENT_SOURCES_PERFORMANCE = 'titan-event-source-performance',
  TITAN_FLIGHT = 'titan-campaign-flight',
  TITAN_INVENTORY_SOURCES_LIST = 'gemini-inventory-sources-list',
  TITAN_KPI_TYPES_LIST = 'gemini-kpi-types-list',
  TITAN_NOTIFICATION = 'titan-notification',
  TITAN_NOTIFICATION_TYPES_LIST = 'titan-notification-types-list',
  TITAN_NOTIFICATIONS = 'titan-notifications',
  TITAN_PAGE_TYPES_LIST = 'titan-page-types-list',
  TITAN_PAGES = 'titan-pages',
  TITAN_PARTNER = 'titan-partner',
  TITAN_PARTNERS = 'titan-partners',
  TITAN_PRODUCT_TYPES_LIST = 'titan-product-types-list',
  TITAN_SALES_REGIONS_LIST = 'titan-sales-regions-list',
  TITAN_VIRTUAL_EVENT_SOURCE = 'titan-virtual-event-source',
  TITAN_VIRTUAL_EVENT_SOURCES = 'titan-virtual-event-sources',
}
