import React, { createContext, FC, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { EMPTY_FUNCTION } from '@cognitiv/cassiopeia-ui';
import { selectMegaMenuCampaigns, selectMegaMenuDeals, selectMegaMenuSegments } from 'ducks/mega-menu/selectors';
import { selectOverlay } from 'ducks/overlays/selectors';
import { updateOverlay } from 'ducks/overlays/slices';
import { CHUNK_NO_HEADER_MAX_ROWS, CHUNK_WITH_HEADER_MAX_ROWS, MAX_COLUMNS } from 'overlays/mega-menu/constants';
import { EXPANDED_VIEW, MEGA_MENU_ENTITY_TYPE, MEGA_MENU_VIEW } from 'overlays/mega-menu/enums';
import { MegaMenuChunk, MegaMenuOption } from 'overlays/mega-menu/types';
import { TITAN_PRODUCT_TYPE_ID } from 'products/titan/operators/product-types-list/enums';
import { GEMINI, ORION, VIRGO } from 'routes';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { uuidv4 } from 'utils/uuid';

interface MegaMenuContextProps {
  product_type: TITAN_PRODUCT_TYPE_ID | null;
  advertiser_id: number | null;
  [MEGA_MENU_ENTITY_TYPE.CAMPAIGNS]: MegaMenuOption[];
  [MEGA_MENU_ENTITY_TYPE.SEGMENTS]: MegaMenuOption[];
  [MEGA_MENU_ENTITY_TYPE.DEALS]: MegaMenuOption[];
  view: MEGA_MENU_VIEW;
  updateView: (view: MEGA_MENU_ENTITY_TYPE | null) => void;
  getBasePath: (entity_type: MEGA_MENU_ENTITY_TYPE) => string;
  getChunks: () => MegaMenuChunk[];
  onClose: () => void;
}

export const default_mega_menu_context = {
  product_type: null,
  advertiser_id: null,
  [MEGA_MENU_ENTITY_TYPE.CAMPAIGNS]: [],
  [MEGA_MENU_ENTITY_TYPE.SEGMENTS]: [],
  [MEGA_MENU_ENTITY_TYPE.DEALS]: [],
  view: MEGA_MENU_VIEW.ALL,
  updateView: EMPTY_FUNCTION,
  onClose: EMPTY_FUNCTION,
  getBasePath: () => '',
  getChunks: () => [],
};

const Context = createContext<MegaMenuContextProps>({ ...default_mega_menu_context });

export const MegaMenuContext: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { campaign_id, segment_id, deal_id } = useParams();
  const { page_type_id } = useAppSelector((state) => selectOverlay(state, 'mega_menu'));
  const campaigns = useAppSelector(selectMegaMenuCampaigns);
  const segments = useAppSelector(selectMegaMenuSegments);
  const deals = useAppSelector(selectMegaMenuDeals);
  const [data, setData] = useState<MegaMenuContextProps>({
    ...default_mega_menu_context,
  });

  const updateView = useCallback((view: MEGA_MENU_ENTITY_TYPE | null) => {
    setData((prev) => ({
      ...prev,
      view: view ? EXPANDED_VIEW[view] : MEGA_MENU_VIEW.ALL,
    }));
  }, []);

  const getBasePath = useCallback(
    (entity_type: MEGA_MENU_ENTITY_TYPE) => {
      switch (data.product_type) {
        case TITAN_PRODUCT_TYPE_ID.GEMINI_CORE:
          return `${GEMINI.APPLICATION.path}/${GEMINI.CAMPAIGNS.path}`;
        case TITAN_PRODUCT_TYPE_ID.CONTEXTUAL: {
          switch (entity_type) {
            case MEGA_MENU_ENTITY_TYPE.CAMPAIGNS:
              return `${ORION.APPLICATION.path}/${ORION.CAMPAIGNS.path}`;
            case MEGA_MENU_ENTITY_TYPE.SEGMENTS:
              return `${ORION.APPLICATION.path}/${ORION.SEGMENTS.path}`;
            case MEGA_MENU_ENTITY_TYPE.DEALS:
              return `${ORION.APPLICATION.path}/${ORION.DEALS.path}`;
            default:
              return '';
          }
        }
        case TITAN_PRODUCT_TYPE_ID.CURATION:
          switch (entity_type) {
            case MEGA_MENU_ENTITY_TYPE.CAMPAIGNS:
              return `${VIRGO.APPLICATION.path}/${VIRGO.CAMPAIGNS.path}`;
            case MEGA_MENU_ENTITY_TYPE.DEALS:
              return `${VIRGO.APPLICATION.path}/${VIRGO.DEALS.path}`;
            default:
              return '';
          }
        default:
          return '';
      }
    },
    [data.product_type],
  );

  const onClose = useCallback(() => {
    updateView(null);
    dispatch(updateOverlay({ mega_menu: { is_open: false } }));
  }, [dispatch, updateView]);

  const getProductType = useCallback((): TITAN_PRODUCT_TYPE_ID | null => {
    switch (page_type_id) {
      case ORION.CAMPAIGN.page_type_id:
      case ORION.SEGMENT.page_type_id:
      case ORION.DEAL.page_type_id:
        return TITAN_PRODUCT_TYPE_ID.CONTEXTUAL;
      case VIRGO.CAMPAIGN.page_type_id:
      case VIRGO.DEAL.page_type_id:
        return TITAN_PRODUCT_TYPE_ID.CURATION;
      case GEMINI.CAMPAIGN.page_type_id:
        return TITAN_PRODUCT_TYPE_ID.GEMINI_CORE;
      default:
        return null;
    }
  }, [page_type_id]);

  const getActivePage = useCallback((): {
    active_page_type: MEGA_MENU_ENTITY_TYPE | null;
    active_page_id: number | null;
    advertiser_id: number | null;
  } => {
    if (campaign_id) {
      const active_campaign = campaigns.find((c) => c.campaign_id === Number(campaign_id));
      return {
        active_page_type: MEGA_MENU_ENTITY_TYPE.CAMPAIGNS,
        active_page_id: Number(campaign_id),
        advertiser_id: active_campaign?.advertiser_id || null,
      };
    }
    if (segment_id) {
      const active_segment = segments.find((c) => c.segment_id === Number(segment_id));
      return {
        active_page_type: MEGA_MENU_ENTITY_TYPE.SEGMENTS,
        active_page_id: Number(segment_id),
        advertiser_id: active_segment?.advertiser_id || null,
      };
    }
    if (deal_id) {
      const active_deal = deals.find((c) => c.deal_id === Number(deal_id));
      return {
        active_page_type: MEGA_MENU_ENTITY_TYPE.DEALS,
        active_page_id: Number(deal_id),
        advertiser_id: active_deal?.advertiser_id || null,
      };
    }
    return { active_page_type: null, active_page_id: null, advertiser_id: null };
  }, [campaign_id, segment_id, deal_id, campaigns, segments, deals]);

  const getParentsAndAdvertiser = useCallback(
    ({ active_page_type, active_page_id }: { active_page_type: MEGA_MENU_ENTITY_TYPE | null; active_page_id: number | null }) => {
      if (active_page_type === MEGA_MENU_ENTITY_TYPE.CAMPAIGNS) {
        const campaign = campaigns.find((c) => c.campaign_id === active_page_id);
        return { parents: { campaign_id: active_page_id, segment_id: null }, advertiser_id: campaign?.advertiser_id || null };
      }

      if (active_page_type === MEGA_MENU_ENTITY_TYPE.SEGMENTS) {
        const segment = segments.find((s) => s.segment_id === active_page_id);
        return { parents: { campaign_id: segment?.campaign_id || null, segment_id: null }, advertiser_id: segment?.advertiser_id || null };
      }

      if (active_page_type === MEGA_MENU_ENTITY_TYPE.DEALS) {
        const deal = deals.find((d) => d.deal_id === active_page_id);
        return {
          parents: { campaign_id: deal?.campaign_id || null, segment_id: deal?.contextual_segment_id || null },
          advertiser_id: deal?.advertiser_id || null,
        };
      }

      return { parents: { campaign_id: null, segment_id: null }, advertiser_id: null };
    },
    [campaigns, segments, deals],
  );

  const getSortedData = useCallback(
    ({
      active_page_type,
      active_page_id,
      parents,
    }: {
      active_page_type: MEGA_MENU_ENTITY_TYPE | null;
      active_page_id: number | null;
      parents: { campaign_id: number | null; segment_id: number | null };
    }): { campaigns: MegaMenuOption[]; segments: MegaMenuOption[]; deals: MegaMenuOption[] } => {
      const sortData = (data: MegaMenuOption[]) => {
        const sorted = data.sort((a, b) => {
          if (a.selected && !b.selected) return -1;
          if (!a.selected && b.selected) return 1;
          if (a.is_parent && !b.is_parent) return -1;
          if (!a.is_parent && b.is_parent) return 1;
          return 0;
        });
        return sorted;
      };

      const campaigns_data = sortData(
        campaigns.map((campaign) => ({
          label: campaign.campaign_name,
          id: campaign.campaign_id,
          selected: active_page_type === MEGA_MENU_ENTITY_TYPE.CAMPAIGNS && active_page_id === campaign.campaign_id,
          is_parent: parents.campaign_id === campaign.campaign_id,
          uuid: uuidv4(),
          entity_type: MEGA_MENU_ENTITY_TYPE.CAMPAIGNS,
        })),
      );

      const segments_data = sortData(
        segments.map((segment) => ({
          label: segment.segment_name,
          id: segment.segment_id,
          is_parent: parents.segment_id === segment.segment_id,
          selected: active_page_type === MEGA_MENU_ENTITY_TYPE.SEGMENTS && active_page_id === segment.segment_id,
          uuid: uuidv4(),
          entity_type: MEGA_MENU_ENTITY_TYPE.SEGMENTS,
        })),
      );

      const deals_data = sortData(
        deals.map((deal) => ({
          label: deal.deal_name,
          id: deal.deal_id,
          is_parent: false,
          selected: active_page_type === MEGA_MENU_ENTITY_TYPE.DEALS && active_page_id === deal.deal_id,
          uuid: uuidv4(),
          entity_type: MEGA_MENU_ENTITY_TYPE.DEALS,
        })),
      );

      return { campaigns: campaigns_data, segments: segments_data, deals: deals_data };
    },
    [campaigns, segments, deals],
  );

  const configureChunks = useCallback(
    ({ entity_type, columns = MAX_COLUMNS }: { entity_type: MEGA_MENU_ENTITY_TYPE; columns?: number }): MegaMenuChunk[] => {
      const chunk_data = data[entity_type];
      if (chunk_data.length === 0) {
        return [];
      }

      const view_type_max_rows = data.view === MEGA_MENU_VIEW.ALL ? CHUNK_WITH_HEADER_MAX_ROWS : CHUNK_NO_HEADER_MAX_ROWS;

      const chunk_count = Math.ceil(chunk_data.length / view_type_max_rows);
      const is_overflow = chunk_count > columns;
      const chunks = is_overflow ? columns : chunk_count;

      const result: MegaMenuChunk[] = Array.from({ length: chunks }, (_, index) => {
        const last_chunk = index === chunks - 1;
        const first_chunk = index === 0;

        const getRowCount = () => {
          if (first_chunk && last_chunk && is_overflow) {
            return view_type_max_rows - 1;
          } else if (first_chunk && last_chunk) {
            return view_type_max_rows;
          } else if (!first_chunk && !last_chunk) {
            return CHUNK_NO_HEADER_MAX_ROWS;
          } else if (last_chunk && is_overflow) {
            return CHUNK_NO_HEADER_MAX_ROWS - 1;
          }
          return view_type_max_rows;
        };
        const row_count = getRowCount();

        const items = chunk_data.slice(index * row_count, (index + 1) * row_count);

        return {
          is_first_of_type: index === 0,
          more_options: index === chunks - 1 && !!is_overflow,
          entity_type: entity_type,
          data: items,
          uuid: uuidv4(),
        };
      });

      return result;
    },
    [data.view, data[MEGA_MENU_ENTITY_TYPE.CAMPAIGNS], data[MEGA_MENU_ENTITY_TYPE.SEGMENTS], data[MEGA_MENU_ENTITY_TYPE.DEALS]],
  );

  const getViewAllChunks = useCallback(() => {
    switch (data.product_type) {
      case TITAN_PRODUCT_TYPE_ID.CONTEXTUAL:
        return [
          ...configureChunks({
            entity_type: MEGA_MENU_ENTITY_TYPE.CAMPAIGNS,
            columns: 1,
          }),
          ...configureChunks({
            entity_type: MEGA_MENU_ENTITY_TYPE.SEGMENTS,
            columns: 1,
          }),
          ...configureChunks({
            entity_type: MEGA_MENU_ENTITY_TYPE.DEALS,
            columns: 1,
          }),
        ];
      case TITAN_PRODUCT_TYPE_ID.CURATION: {
        const campaigns = data[MEGA_MENU_ENTITY_TYPE.CAMPAIGNS];
        const deals = data[MEGA_MENU_ENTITY_TYPE.DEALS];

        let campaign_chunks = 1;
        let deal_chunks = 1;

        if (deals.length > CHUNK_WITH_HEADER_MAX_ROWS && deals.length > campaigns.length) {
          deal_chunks = 2;
        } else if (campaigns.length > CHUNK_WITH_HEADER_MAX_ROWS && campaigns.length > deals.length) {
          campaign_chunks = 2;
        }

        return [
          ...configureChunks({
            entity_type: MEGA_MENU_ENTITY_TYPE.CAMPAIGNS,
            columns: campaign_chunks,
          }),
          ...configureChunks({
            entity_type: MEGA_MENU_ENTITY_TYPE.DEALS,
            columns: deal_chunks,
          }),
        ];
      }
      case TITAN_PRODUCT_TYPE_ID.GEMINI_CORE:
        return configureChunks({
          entity_type: MEGA_MENU_ENTITY_TYPE.CAMPAIGNS,
          columns: 3,
        });
      default:
        return [];
    }
  }, [configureChunks, data]);

  const getChunks = useCallback((): MegaMenuChunk[] => {
    switch (data.view) {
      case MEGA_MENU_VIEW.CAMPAIGNS:
        return configureChunks({
          entity_type: MEGA_MENU_ENTITY_TYPE.CAMPAIGNS,
        });
      case MEGA_MENU_VIEW.SEGMENTS:
        return configureChunks({
          entity_type: MEGA_MENU_ENTITY_TYPE.SEGMENTS,
        });
      case MEGA_MENU_VIEW.DEALS:
        return configureChunks({
          entity_type: MEGA_MENU_ENTITY_TYPE.DEALS,
        });
      case MEGA_MENU_VIEW.ALL:
        return getViewAllChunks();
      default:
        return [];
    }
  }, [data.view, configureChunks, getViewAllChunks]);

  useEffect(() => {
    const setUpContext = () => {
      const product_type = getProductType();
      const { active_page_type, active_page_id } = getActivePage();
      const { parents, advertiser_id } = getParentsAndAdvertiser({ active_page_type, active_page_id });
      const { campaigns, segments, deals } = getSortedData({ active_page_type, active_page_id, parents });

      setData(() => ({
        ...default_mega_menu_context,
        product_type,
        advertiser_id,
        [MEGA_MENU_ENTITY_TYPE.CAMPAIGNS]: campaigns,
        [MEGA_MENU_ENTITY_TYPE.SEGMENTS]: segments,
        [MEGA_MENU_ENTITY_TYPE.DEALS]: deals,
      }));
    };
    setUpContext();
  }, [getParentsAndAdvertiser, getProductType, getActivePage, getSortedData]);

  const value = useMemo(
    () => ({
      ...data,
      updateView,
      onClose,
      getChunks,
      getBasePath,
    }),
    [data, updateView, onClose, getChunks, getBasePath],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useMegaMenuContext = () => useContext(Context);
