import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MegaMenuSlice } from 'ducks/mega-menu/types';
import { SLICE } from 'ducks/slice';

const initialState: MegaMenuSlice = {
  campaigns: [],
  segments: [],
  deals: [],
};

export const megaMenusSlice = createSlice({
  name: SLICE.MEGA_MENU,
  initialState,
  reducers: {
    clearMegaMenu: () => {
      return initialState;
    },
    setMegaMenu: (_, action: PayloadAction<MegaMenuSlice>) => {
      return action.payload;
    },
  },
});

export const { clearMegaMenu, setMegaMenu } = megaMenusSlice.actions;

export default megaMenusSlice.reducer;
