import React, { CSSProperties, FC, PropsWithChildren } from 'react';

import cn from 'components/layout/page/Page.module.scss';

export const Page: FC<PropsWithChildren<{ style?: CSSProperties }>> = ({ children, style = {} }) => {
  return (
    <div className={cn.page} style={style}>
      <div className={cn.wrapper}>{children}</div>
    </div>
  );
};
