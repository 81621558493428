import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@cognitiv/cassiopeia-ui';
import { faCheck, faExpand } from '@fortawesome/pro-regular-svg-icons';
import { faUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import { MEGA_MENU_ENTITY_TYPE, MEGA_MENU_VIEW } from 'overlays/mega-menu/enums';
import { useMegaMenuContext } from 'overlays/mega-menu/MegaMenuContext';
import { MegaMenuOption, MegaMenuOptionShowMore } from 'overlays/mega-menu/types';
import { colors } from 'theme/colors';

import cn from 'overlays/mega-menu/components/option/Option.module.scss';

export const Option = ({ option }: { option: MegaMenuOption }) => {
  const navigate = useNavigate();
  const { onClose, getBasePath } = useMegaMenuContext();

  const onSelect = useCallback(
    (entity: MegaMenuOption) => {
      navigate(`${getBasePath(option.entity_type)}/${entity.id}`);
      onClose();
    },
    [navigate, option.entity_type, getBasePath, onClose],
  );

  return (
    <div className={classNames(cn.option, { [cn.selected]: option.selected, [cn.parent]: option.is_parent })} onClick={() => onSelect(option)}>
      <p>{option.label}</p>
      {option.selected && <Icon icon={faCheck} theme={{ icon: { color: colors.blue[6] } }} />}
    </div>
  );
};

export const ShowMoreOptions = ({ onClick }: MegaMenuOptionShowMore) => {
  return (
    <div className={cn.option} onClick={onClick}>
      <Icon icon={faExpand} theme={{ icon: { color: colors.blue[6] } }} />
      <p style={{ color: colors.blue[6] }}>View More</p>
    </div>
  );
};

export const ShowAllOptions = () => {
  const navigate = useNavigate();
  const { getBasePath, view, advertiser_id, onClose } = useMegaMenuContext();

  const onClick = useCallback(() => {
    const entity_type =
      view === MEGA_MENU_VIEW.CAMPAIGNS
        ? MEGA_MENU_ENTITY_TYPE.CAMPAIGNS
        : view === MEGA_MENU_VIEW.SEGMENTS
        ? MEGA_MENU_ENTITY_TYPE.SEGMENTS
        : MEGA_MENU_ENTITY_TYPE.DEALS;
    navigate(`${getBasePath(entity_type)}?advertiser_id=${advertiser_id}`);
    onClose();
  }, [navigate, getBasePath, onClose, view, advertiser_id]);

  return (
    <div className={cn.option} onClick={onClick}>
      <Icon icon={faUpRightFromSquare} theme={{ icon: { color: colors.blue[6] } }} />
      <p style={{ color: colors.blue[6] }}>View All</p>
    </div>
  );
};
