import { HyperionCsCampaignPerformanceTracker, HyperionCsCampaignPerformanceTrackerSummary } from '@cognitiv/galaxy-api';

export const hyperion_cs_campaign_performance_tracker_summary_default: HyperionCsCampaignPerformanceTrackerSummary = {
  budget_value: 0,
  yesterday_spend_usd: 0,
  yesterday_impressions: 0,
  yesterday_pacing_target_usd: 0,
  yesterday_margin_percent: 0,
  yesterday_margin_percent_formatted: '0',
  yesterday_margin_usd: 0,
  yesterday_margin_usd_formatted: '0',
  last_seven_day_average_impressions: 0,
  last_seven_day_average_spend_usd: 0,
  last_seven_day_average_margin_percent: 0,
  last_seven_day_average_margin_percent_formatted: '0',
  last_seven_day_average_margin_usd: 0,
  last_seven_day_average_margin_usd_formatted: '0',
  month_to_date_spend_usd: 0,
  month_to_date_impressions: 0,
  month_forecast_usd: 0,
  month_to_date_margin_percent: 0,
  month_to_date_margin_percent_formatted: '0',
  month_to_date_margin_usd: 0,
  month_to_date_margin_usd_formatted: '0',
  remaining_month_spend_usd: 0,
  percent_month_to_date_spend_usd: 0,
  year_to_date_spend_usd: 0,
  year_to_date_impressions: 0,
  year_to_date_margin_percent: 0,
  year_to_date_margin_percent_formatted: '0',
  year_to_date_margin_usd: 0,
  year_to_date_margin_usd_formatted: '0',
  year_forecast_usd: 0,
  remaining_year_to_date_spend_usd: 0,
  percent_year_to_date_spend_usd: 0,
  budget_value_formatted: '',
  yesterday_spend_usd_formatted: '',
  yesterday_impressions_formatted: '',
  yesterday_pacing_target_usd_formatted: '',
  last_seven_day_average_impressions_formatted: '',
  last_seven_day_average_spend_usd_formatted: '',
  month_to_date_spend_usd_formatted: '',
  month_to_date_impressions_formatted: '',
  month_forecast_usd_formatted: '',
  remaining_month_spend_usd_formatted: '',
  percent_month_to_date_spend_usd_formatted: '',
  year_to_date_spend_usd_formatted: '',
  year_to_date_impressions_formatted: '',
  year_forecast_usd_formatted: '',
  remaining_year_to_date_spend_usd_formatted: '',
  percent_year_to_date_spend_usd_formatted: '',
  uuid: '',
};

export const hyperion_cs_campaign_performance_tracker_default: HyperionCsCampaignPerformanceTracker = {
  summary: { ...hyperion_cs_campaign_performance_tracker_summary_default },
  data: [],
  uuid: '',
};
