import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { default_lookback_slice } from 'ducks/lookback/defaults';
import { LookbackSlice } from 'ducks/lookback/types';
import { SLICE } from 'ducks/slice';

const initialState: LookbackSlice = { ...default_lookback_slice };

export const lookbackSlice = createSlice({
  name: SLICE.LOOKBACK,
  initialState,
  reducers: {
    clearLookback: () => initialState,
    setLookback: (state, action: PayloadAction<Partial<LookbackSlice>>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { clearLookback, setLookback } = lookbackSlice.actions;

export default lookbackSlice.reducer;
