import { HyperionInterval } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/hyperion/operators/slice';

const initialState: HyperionInterval[] = [];

export const hyperionIntervalsListSlice = createSlice({
  name: SLICE.HYPERION_INTERVALS_LIST,
  initialState,
  reducers: {
    clearHyperionIntervalsList: () => {
      return initialState;
    },
    setHyperionIntervalsList: (_, action: PayloadAction<HyperionInterval[]>) => action.payload,
  },
});

export const { clearHyperionIntervalsList, setHyperionIntervalsList } = hyperionIntervalsListSlice.actions;

export default hyperionIntervalsListSlice.reducer;
