import { GeminiCampaign } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { gemini_campaign_default } from 'products/gemini/operators/campaign/defaults';
import { SLICE } from 'products/gemini/operators/slice';

const initialState: GeminiCampaign = { ...gemini_campaign_default };

export const geminiCampaignSlice = createSlice({
  name: SLICE.GEMINI_CAMPAIGN,
  initialState,
  reducers: {
    clearGeminiCampaign: () => initialState,
    setGeminiCampaign: (_, action: PayloadAction<GeminiCampaign>) => action.payload,
  },
});

export const { clearGeminiCampaign, setGeminiCampaign } = geminiCampaignSlice.actions;

export default geminiCampaignSlice.reducer;
