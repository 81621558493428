import { LookbackData, LookbackSlice } from 'ducks/lookback/types';

import { LOOKBACK_TYPE } from 'components/tables/lookback-days/enums';

export const default_lookback_data: LookbackData = {
  selection: {
    label: '',
    lookback_days: null,
    uuid: '',
  },
  options: [],
  lookback_parameter: '',
};

export const default_lookback_slice: LookbackSlice = {
  [LOOKBACK_TYPE.INBOUND_INBOX]: { ...default_lookback_data },
  [LOOKBACK_TYPE.IMPORT_CONFIGS_HEALTH]: { ...default_lookback_data },
  [LOOKBACK_TYPE.CONFIG_REPORT_HISTORY]: { ...default_lookback_data },
  [LOOKBACK_TYPE.DEAL_SSP]: { ...default_lookback_data },
  [LOOKBACK_TYPE.CAMPAIGN_RAW_REPORT]: { ...default_lookback_data },
};
