import { LookbackAggregateData, LookbackAggregateSlice } from 'ducks/lookback-aggregate/types';

import { LOOKBACK_AGGREGATE_TYPE } from 'components/tables/lookback-aggregate/enums';

export const default_lookback_aggregate_data: LookbackAggregateData = {
  selection: {
    label: '',
    lookback_days: null,
    aggregate_type_id: null,
    uuid: '',
  },
  options: [],
  lookback_parameter: '',
  aggregate_parameter: '',
};

export const default_lookback_aggregate_slice: LookbackAggregateSlice = {
  [LOOKBACK_AGGREGATE_TYPE.CAMPAIGN_PERFORMANCE]: { ...default_lookback_aggregate_data },
  [LOOKBACK_AGGREGATE_TYPE.GEMINI_CAMPAIGN_DCPM]: { ...default_lookback_aggregate_data },
};
