import { LibraDeal, OrionSegment, TitanCampaign } from '@cognitiv/galaxy-api';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';

const megaMenusSlice = (state: RootState) => state.mega_menu;

export const selectMegaMenuCampaigns = createSelector([megaMenusSlice], (state): TitanCampaign[] => state.campaigns);

export const selectMegaMenuSegments = createSelector([megaMenusSlice], (state): OrionSegment[] => state.segments);

export const selectMegaMenuDeals = createSelector([megaMenusSlice], (state): LibraDeal[] => state.deals);
