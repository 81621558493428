import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { default_lookback_aggregate_slice } from 'ducks/lookback-aggregate/defaults';
import { LookbackAggregateSlice } from 'ducks/lookback-aggregate/types';
import { SLICE } from 'ducks/slice';

const initialState: LookbackAggregateSlice = {
  ...default_lookback_aggregate_slice,
};

export const lookbackAggregateSlice = createSlice({
  name: SLICE.LOOKBACK,
  initialState,
  reducers: {
    clearLookbackAggregate: () => initialState,
    setLookbackAggregate: (state, action: PayloadAction<Partial<LookbackAggregateSlice>>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { clearLookbackAggregate, setLookbackAggregate } = lookbackAggregateSlice.actions;

export default lookbackAggregateSlice.reducer;
