import { GeminiCampaign } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/gemini/operators/slice';

const initialState: GeminiCampaign[] = [];

export const geminiCampaignsSlice = createSlice({
  name: SLICE.GEMINI_CAMPAIGNS,
  initialState,
  reducers: {
    clearGeminiCampaigns: () => initialState,
    setGeminiCampaigns: (_, action: PayloadAction<GeminiCampaign[]>) => action.payload,
  },
});

export const { clearGeminiCampaigns, setGeminiCampaigns } = geminiCampaignsSlice.actions;

export default geminiCampaignsSlice.reducer;
