export enum LOOKBACK_VALUE {
  WEEK = 7,
  MONTH = 30,
}

export enum LOOKBACK_DAYS_LABEL {
  WEEK = '1 Week',
  MONTH = '30 Days',
  ALL_TIME = 'All Time',
}

export enum LOOKBACK_TYPE {
  INBOUND_INBOX = 1,
  IMPORT_CONFIGS_HEALTH = 2,
  CONFIG_REPORT_HISTORY = 3,
  DEAL_SSP = 4,
  CAMPAIGN_RAW_REPORT = 5,
}
