import React, { useEffect } from 'react';
import { batch } from 'react-redux';
import { Outlet } from 'react-router';
import { useLocation } from 'react-router-dom';
import { parseQueries } from '@cognitiv/cicada-ui';
import { useClientContext } from '@cognitiv/galaxy-api';
import { selectSettings } from 'ducks/settings/selectors';
import { setTitanAdvertisers } from 'products/titan/operators/advertisers/slices';
import { setTitanCreativeAdServersList } from 'products/titan/operators/creative-ad-servers-list/slices';
import { setTitanCreativeAddons } from 'products/titan/operators/creative-addons-list/slices';
import { setTitanCreativeBannerSizesList } from 'products/titan/operators/creative-banner-sizes-list/slices';
import { setTitanCreativeTypesList } from 'products/titan/operators/creative-types-list/slices';
import { setTitanDeliveryTypesList } from 'products/titan/operators/delivery-types-list/slices';
import { setTitanEventSourceCategoriesList } from 'products/titan/operators/event-source-categories-list/slices';
import { setTitanEventSourceDataProvidersList } from 'products/titan/operators/event-source-data-providers-list/slices';
import { setTitanEventSourceOperatorTypesList } from 'products/titan/operators/event-source-operator-types-list/slices';
import { setTitanEventSourcePropertyTypesList } from 'products/titan/operators/event-source-property-types-list/slices';
import { setTitanEventSourceTypesList } from 'products/titan/operators/event-source-types-list/slices';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { Content } from 'components/layout/content/Content';
import { Menu } from 'components/menu/Menu';

import cn from 'products/titan/template/ApplicationTemplate.module.scss';

export default () => {
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const { update } = parseQueries(search);
  const { titan } = useAppSelector(selectSettings);
  const { Titan, handleError } = useClientContext();

  useEffect(() => {
    const getData = async () => {
      try {
        const [
          titan_event_source_categories_list,
          titan_event_source_types_list,
          event_source_property_types,
          event_source_operator_types,
          titan_event_source_data_providers_list,
          titan_advertisers,
          titan_creative_addons,
          titan_creative_types,
          titan_creative_ad_servers,
          titan_creative_banner_sizes,
          titan_delivery_types_list,
        ] = await Promise.all([
          Titan.getTitanEventSourceCategoriesList(),
          Titan.getTitanEventSourceTypesList(),
          Titan.getTitanEventSourcePropertyTypesList(),
          Titan.getTitanEventSourceOperatorTypesList(),
          Titan.getTitanEventSourceDataProvidersList(),
          Titan.getTitanAdvertisers(),
          Titan.getTitanCreativeAddons(),
          Titan.getTitanCreativeTypesList(),
          Titan.getTitanCreativeAdServersList(),
          Titan.getTitanCreativeBannerSizesList(),
          Titan.getTitanDeliveryTypesList(),
        ]);

        batch(() => {
          dispatch(setTitanEventSourceCategoriesList(titan_event_source_categories_list));
          dispatch(setTitanEventSourceTypesList(titan_event_source_types_list));
          dispatch(setTitanEventSourcePropertyTypesList(event_source_property_types));
          dispatch(setTitanEventSourceOperatorTypesList(event_source_operator_types));
          dispatch(setTitanEventSourceDataProvidersList(titan_event_source_data_providers_list));
          dispatch(setTitanAdvertisers(titan_advertisers));
          dispatch(setTitanCreativeAddons(titan_creative_addons));
          dispatch(setTitanCreativeTypesList(titan_creative_types));
          dispatch(setTitanCreativeAdServersList(titan_creative_ad_servers));
          dispatch(setTitanCreativeBannerSizesList(titan_creative_banner_sizes));
          dispatch(setTitanDeliveryTypesList(titan_delivery_types_list));
        });
      } catch (err) {
        handleError(err);
      }
    };

    if (titan) {
      getData();
    }
  }, [dispatch, titan, update, Titan, handleError]);

  return (
    <div className={cn.template}>
      <Menu />
      <Content>
        <Outlet />
      </Content>
    </div>
  );
};
