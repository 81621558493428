import React, { useEffect } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { batch } from 'react-redux';
import { Outlet } from 'react-router';
import { Navigate } from 'react-router-dom';
import { ThemeProvider, useRootTheme } from '@cognitiv/cassiopeia-ui';
import { useClientContext } from '@cognitiv/galaxy-api';
import { selectModal } from 'ducks/modals/selectors';
import { updateModal } from 'ducks/modals/slices';
import { selectSettings } from 'ducks/settings/selectors';
import { selectUserAuthentication } from 'ducks/user/selectors';
import { SearchCommandContext } from 'modals/search/context/SearchCommandContext';
import { Search } from 'modals/search/Search';
import { Confirm } from 'modals/utility/Confirm';
import { Error } from 'modals/utility/Error';
import { MegaMenu } from 'overlays/mega-menu/MegaMenu';
import { setTitanPageTypesList } from 'products/titan/operators/page-types-list/slices';
import { PUBLIC } from 'routes';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { LOADING_VARIANTS } from 'components/layout/loading/enums';
import { Loading } from 'components/layout/loading/Loading';
import { Snackbar } from 'components/layout/snackbar/Snackbar';
import { MenuContext } from 'components/menu/context/MenuContext';

import cn from 'templates/Template.module.scss';

export default () => {
  const dispatch = useAppDispatch();
  const authenticated = useAppSelector((state) => selectUserAuthentication(state));
  const { is_open } = useAppSelector((state) => selectModal(state, 'search'));
  const { loading, theme: theme_variant } = useAppSelector(selectSettings);

  useRootTheme(theme_variant, 'luna');

  const { Titan, handleError } = useClientContext();

  useHotkeys(
    'mod+k',
    () => {
      dispatch(updateModal({ search: { is_open: !is_open } }));
    },
    {
      enableOnFormTags: true,
      enableOnContentEditable: true,
    },
  );

  useEffect(() => {
    const getData = async () => {
      try {
        const [titan_page_types] = await Promise.all([Titan.getTitanPageTypes()]);

        batch(() => {
          dispatch(setTitanPageTypesList(titan_page_types));
        });
      } catch (err) {
        handleError(err);
      }
    };
    const timeout = setTimeout(() => getData(), 1000);
    return () => clearTimeout(timeout);
  }, [Titan, handleError, dispatch]);

  if (!authenticated) {
    return <Navigate to={PUBLIC.LOGIN.path} state={{ referer: `${location.pathname}${location.search}` }} />;
  }

  return (
    <ThemeProvider theme_variant={theme_variant}>
      <MenuContext>
        <SearchCommandContext>
          <div className={cn.private}>
            <Outlet />
            <Search />
            <Snackbar />
            <Error />
            <Confirm />
            <MegaMenu />
            {loading && <Loading variant={LOADING_VARIANTS.DARK} />}
          </div>
        </SearchCommandContext>
      </MenuContext>
    </ThemeProvider>
  );
};
