import React, { memo, useMemo, useRef } from 'react';
import { CaptureFixedClick } from '@cognitiv/cassiopeia-ui';
import classNames from 'classnames';
import { selectOverlay } from 'ducks/overlays/selectors';
import { Column } from 'overlays/mega-menu/components/column/Column';
import { SideMenu } from 'overlays/mega-menu/components/side-menu/SideMenu';
import { MEGA_MENU_VIEW } from 'overlays/mega-menu/enums';
import { MegaMenuContext, useMegaMenuContext } from 'overlays/mega-menu/MegaMenuContext';
import { useAppSelector } from 'store/hooks';

import { MEGA_MENU_ID } from 'components/layout/navigation/MegaMenuBreadcrumb';

import cn from 'overlays/mega-menu/MegaMenu.module.scss';

const Menu = memo(() => {
  const { getChunks, view, onClose } = useMegaMenuContext();

  const ref = useRef<HTMLDivElement | null>(null);
  const { is_open } = useAppSelector((state) => selectOverlay(state, 'mega_menu'));

  const chunks = useMemo(() => getChunks(), [getChunks]);

  return (
    <CaptureFixedClick
      refs={[ref]}
      onClickOutside={(e) => {
        const dropdown = document.getElementById(MEGA_MENU_ID);
        if (!dropdown?.contains(e.target as HTMLElement)) {
          onClose();
        }
      }}
    >
      <div className={classNames(cn.menu, { [cn.is_open]: is_open })} ref={ref}>
        {view !== MEGA_MENU_VIEW.ALL && <SideMenu />}
        <div className={classNames(cn.columns, { [cn.max]: view !== MEGA_MENU_VIEW.ALL })}>
          {chunks.map((chunk) => {
            return <Column key={chunk.uuid} chunk={chunk} />;
          })}
        </div>
      </div>
    </CaptureFixedClick>
  );
});

export const MegaMenu = () => {
  return (
    <MegaMenuContext>
      <Menu />
    </MegaMenuContext>
  );
};
