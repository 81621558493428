export enum MEGA_MENU_ENTITY_TYPE {
  CAMPAIGNS = 1,
  SEGMENTS = 2,
  DEALS = 3,
}

export enum MEGA_MENU_VIEW {
  CAMPAIGNS = 1,
  SEGMENTS = 2,
  DEALS = 3,
  ALL = 4,
}

export const EXPANDED_VIEW: Record<MEGA_MENU_ENTITY_TYPE, MEGA_MENU_VIEW> = {
  [MEGA_MENU_ENTITY_TYPE.CAMPAIGNS]: MEGA_MENU_VIEW.CAMPAIGNS,
  [MEGA_MENU_ENTITY_TYPE.SEGMENTS]: MEGA_MENU_VIEW.SEGMENTS,
  [MEGA_MENU_ENTITY_TYPE.DEALS]: MEGA_MENU_VIEW.DEALS,
};
