import { TitanCampaignAttribution } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/titan/operators/slice';

const initialState: TitanCampaignAttribution[] = [];

export const titanCampaignAttributionsSlice = createSlice({
  name: SLICE.TITAN_CAMPAIGN_ATTRIBUTIONS,
  initialState,
  reducers: {
    clearTitanCampaignAttributions: () => initialState,
    setTitanCampaignAttributions: (_, action: PayloadAction<TitanCampaignAttribution[]>) => action.payload,
  },
});

export const { clearTitanCampaignAttributions, setTitanCampaignAttributions } = titanCampaignAttributionsSlice.actions;

export default titanCampaignAttributionsSlice.reducer;
