export enum AGGREGATE_TYPE_VALUE {
  DAILY = 1,
  WEEKLY = 2,
  MONTHLY = 3,
}

export enum LOOKBACK_AGGREGATE_LABEL {
  DAILY_WEEK = 'Daily: Last 7 Days',
  DAILY_MONTH = 'Daily: Last 30 Days',
  WEEKLY_MONTH = 'Weekly: Last 30 Days',
  WEEKLY_ALL_TIME = 'Weekly: All Time',
  MONTHLY_ALL_TIME = 'Monthly: All Time',
}

export enum LOOKBACK_AGGREGATE_TYPE {
  CAMPAIGN_PERFORMANCE = 1,
  GEMINI_CAMPAIGN_DCPM = 2,
}
