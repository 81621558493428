import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faBullhorn, faFilter, faTag } from '@fortawesome/pro-regular-svg-icons';
import { MEGA_MENU_ENTITY_TYPE } from 'overlays/mega-menu/enums';

export const CHUNK_WITH_HEADER_MAX_ROWS = 9;
export const CHUNK_NO_HEADER_MAX_ROWS = 10;
export const MAX_COLUMNS = 4;
export const { PUBLIC_URL } = process.env;

export const MEGA_MENU_CHUNK_META_DATA: Record<MEGA_MENU_ENTITY_TYPE, { label: string; icon: IconProp; image: string }> = {
  [MEGA_MENU_ENTITY_TYPE.CAMPAIGNS]: {
    label: 'Campaigns',
    icon: faBullhorn,
    image: `${PUBLIC_URL}/mega-menu-campaign.png`,
  },
  [MEGA_MENU_ENTITY_TYPE.SEGMENTS]: {
    label: 'Segments',
    icon: faFilter,
    image: `${PUBLIC_URL}/mega-menu-segment.png`,
  },
  [MEGA_MENU_ENTITY_TYPE.DEALS]: {
    label: 'Deals',
    icon: faTag,
    image: `${PUBLIC_URL}/mega-menu-deal.png`,
  },
};
