import {
  HyperionAdvertiserAdServerReport,
  HyperionAdvertiserDspReport,
  HyperionCampaignPerformance,
  HyperionCampaignPerformanceSummary,
  HyperionCampaignRtbDcpmReport,
  HyperionCampaignRtbPerformance,
  HyperionCampaignRtbPerformanceSummary,
  HyperionCampaignSspReport,
  HyperionDealPerformanceSummary,
  HyperionDealSspReport,
} from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { hyperion_reporting_default } from 'products/hyperion/operators/reporting/defaults';
import { HyperionReporting } from 'products/hyperion/operators/reporting/types';
import { SLICE } from 'products/hyperion/operators/slice';

const initialState: HyperionReporting = { ...hyperion_reporting_default };

export const hyperionReportingSlice = createSlice({
  name: SLICE.HYPERION_REPORTING,
  initialState,
  reducers: {
    clearHyperionReporting: () => initialState,
    setHyperionReportingCampaignPerformanceSummary: (state, action: PayloadAction<HyperionCampaignPerformanceSummary>) => {
      state.campaign_performance_summary = action.payload;
    },
    setHyperionReportingCampaignRtbPerformanceSummary: (state, action: PayloadAction<HyperionCampaignRtbPerformanceSummary>) => {
      state.campaign_rtb_performance_summary = action.payload;
    },
    setHyperionReportingCampaignPerformance: (state, action: PayloadAction<HyperionCampaignPerformance[]>) => {
      state.campaign_performance = action.payload;
    },
    setHyperionReportingCampaignRtbPerformance: (state, action: PayloadAction<HyperionCampaignRtbPerformance[]>) => {
      state.campaign_rtb_performance = action.payload;
    },
    setHyperionReportingCampaignRtbDcpmReport: (state, action: PayloadAction<HyperionCampaignRtbDcpmReport[]>) => {
      state.campaign_rtb_dcpm_report = action.payload;
    },
    setHyperionReportingAdvertiserAdserverReport: (state, action: PayloadAction<HyperionAdvertiserAdServerReport[]>) => {
      state.advertiser_adserver_report = action.payload;
    },
    setHyperionReportingAdvertiserDspReport: (state, action: PayloadAction<HyperionAdvertiserDspReport[]>) => {
      state.advertiser_dsp_report = action.payload;
    },
    setHyperionReportingCampaignSspReport: (state, action: PayloadAction<HyperionCampaignSspReport[]>) => {
      state.campaign_ssp_report = action.payload;
    },
    setHyperionReportingDealPerformanceSummary: (state, action: PayloadAction<HyperionDealPerformanceSummary>) => {
      state.deal_performance_summary = action.payload;
    },
    setHyperionReportingDealSspReport: (state, action: PayloadAction<HyperionDealSspReport[]>) => {
      state.deal_ssp_report = action.payload;
    },
  },
});

export const {
  clearHyperionReporting,
  setHyperionReportingCampaignPerformanceSummary,
  setHyperionReportingCampaignRtbPerformanceSummary,
  setHyperionReportingCampaignPerformance,
  setHyperionReportingCampaignRtbPerformance,
  setHyperionReportingCampaignRtbDcpmReport,
  setHyperionReportingAdvertiserAdserverReport,
  setHyperionReportingAdvertiserDspReport,
  setHyperionReportingCampaignSspReport,
  setHyperionReportingDealPerformanceSummary,
  setHyperionReportingDealSspReport,
} = hyperionReportingSlice.actions;

export default hyperionReportingSlice.reducer;
