import { VirgoCampaign } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { virgo_campaign_default } from 'products/virgo/operators/campaign/defaults';
import { SLICE } from 'products/virgo/operators/slice';

const initialState: VirgoCampaign = virgo_campaign_default;

export const virgoCampaignSlice = createSlice({
  name: SLICE.VIRGO_CAMPAIGN,
  initialState,
  reducers: {
    clearVirgoCampaign: () => initialState,
    setVirgoCampaign: (_, action: PayloadAction<VirgoCampaign>) => action.payload,
  },
});

export const { clearVirgoCampaign, setVirgoCampaign } = virgoCampaignSlice.actions;

export default virgoCampaignSlice.reducer;
