import React, { useEffect } from 'react';
import { batch } from 'react-redux';
import { Outlet } from 'react-router';
import { useClientContext } from '@cognitiv/galaxy-api';
import { selectSettings } from 'ducks/settings/selectors';
import { setGeminiRevenueTypesList } from 'products/gemini/operators/revenue-types-list/slices';
import { setTitanAdvertisers } from 'products/titan/operators/advertisers/slices';
import { setTitanCampaignRolePersons } from 'products/titan/operators/campaign-role-persons/slices';
import { setTitanDeliverySourcesList } from 'products/titan/operators/delivery-sources-list/slices';
import { setTitanInventorySourcesList } from 'products/titan/operators/inventory-sources-list/slices';
import { setTitanKpiTypesList } from 'products/titan/operators/kpi-types-list/slices';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { Content } from 'components/layout/content/Content';
import { Breadcrumb } from 'components/layout/navigation/Breadcrumb';
import { Navigation } from 'components/layout/navigation/Navigation';
import { Menu } from 'components/menu/Menu';

import cn from 'products/luna/template/ApplicationTemplate.module.scss';

export default () => {
  const dispatch = useAppDispatch();
  const { Titan, Gemini, handleError } = useClientContext();
  const { luna } = useAppSelector(selectSettings);

  useEffect(() => {
    const getData = async () => {
      try {
        const [
          gemini_revenue_types,
          titan_advertisers,
          titan_inventory_sources,
          titan_kpi_types,
          titan_delivery_sources,
          titan_campaign_role_persons,
        ] = await Promise.all([
          Gemini.getGeminiRevenueTypesList(),
          Titan.getTitanAdvertisers(),
          Titan.getTitanInventorySourcesList(),
          Titan.getTitanKpiTypesList(),
          Titan.getTitanDeliverySourcesList(),
          Titan.getTitanCampaignRolePersons(),
        ]);

        batch(() => {
          dispatch(setGeminiRevenueTypesList(gemini_revenue_types));
          dispatch(setTitanAdvertisers(titan_advertisers));
          dispatch(setTitanInventorySourcesList(titan_inventory_sources));
          dispatch(setTitanKpiTypesList(titan_kpi_types));
          dispatch(setTitanDeliverySourcesList(titan_delivery_sources));
          dispatch(setTitanCampaignRolePersons(titan_campaign_role_persons));
        });
      } catch (err) {
        handleError(err);
      }
    };

    getData();
  }, [dispatch, luna, Titan, handleError, Gemini]);
  return (
    <div className={cn.template}>
      <Menu />
      <Content>
        <Navigation>
          <Breadcrumb label="Dashboard" />
        </Navigation>
        <Outlet />
      </Content>
    </div>
  );
};
