import { VirgoDeal } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { virgo_deal_default } from 'products/virgo/operators/deal/defaults';
import { SLICE } from 'products/virgo/operators/slice';

const initialState: VirgoDeal = virgo_deal_default;

export const virgoDealSlice = createSlice({
  name: SLICE.VIRGO_DEAL,
  initialState,
  reducers: {
    clearVirgoDeal: () => initialState,
    setVirgoDeal: (_, action: PayloadAction<VirgoDeal>) => action.payload,
  },
});

export const { clearVirgoDeal, setVirgoDeal } = virgoDealSlice.actions;

export default virgoDealSlice.reducer;
